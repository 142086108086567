// FrequencyTimer.tsx

import React, { useEffect, useState } from 'react';
import './FrequencyTimer.css';

interface FrequencyTimerProps {
  timeLeft: number;
  onStartNow: () => void;
  onTimerComplete: () => void;
}

const FrequencyTimer: React.FC<FrequencyTimerProps> = ({ timeLeft, onStartNow, onTimerComplete }) => {
  const [remainingTime, setRemainingTime] = useState(timeLeft);
  const [startTime] = useState(Date.now());

  useEffect(() => {
    // Calculate target end time once when component mounts
    const endTime = startTime + timeLeft * 1000;

    const timer = setInterval(() => {
      const now = Date.now();
      const remaining = Math.max(0, Math.ceil((endTime - now) / 1000));
      setRemainingTime(remaining);

      // Update the document title with the timer
      document.title = `${formatTime(remaining)} MotionBreak.com`;

      if (remaining === 0) {
        onTimerComplete();
        clearInterval(timer);
        // Reset the title when timer completes
        document.title = 'MotionBreak.com';
      }
    }, 100); // Update more frequently for smoother display

    // Cleanup: reset title when component unmounts
    return () => {
      clearInterval(timer);
      document.title = 'MotionBreak.com';
    };
  }, [startTime, timeLeft, onTimerComplete]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const paddedSeconds = seconds.toString().padStart(2, '0');
    return `${minutes}:${paddedSeconds}`;
  };

  return (
    <div className="frequency-timer">
      <p className="timer-text">Next session in: {formatTime(remainingTime)}</p>
      <span className="or-text"> Or </span>
      <button onClick={onStartNow} className="start-now-button">
        Start Now
      </button>
    </div>
  );
};

export default FrequencyTimer;
