// Define the list of instructions
export const instructions = [
    {
        instructionText: 'Left hand',
        ballColor: 'green',
        bodyPart: 'leftIndex',
        ballPosition: { x: 100, y: 200 },
    },
    {
        instructionText: 'Right hand',
        ballColor: 'blue',
        bodyPart: 'rightIndex',
        ballPosition: { x: 500, y: 200 },
    },
    {
        instructionText: 'Left foot',
        ballColor: 'red',
        bodyPart: 'leftFoot',
        ballPosition: { x: 100, y: 400 },
    },
    {
        instructionText: 'Right knee',
        ballColor: 'red',
        bodyPart: 'rightKnee',
        ballPosition: { x: 300, y: 300 },
    },
    {
        instructionText: 'Left knee',
        ballColor: 'red',
        bodyPart: 'leftKnee',
        ballPosition: { x: 400, y: 300 },
    },
    {
        instructionText: 'Nose',
        ballColor: 'red',
        bodyPart: 'nose',
        ballPosition: { x: 200, y: 200 },
    },
    {
        instructionText: 'Nose',
        ballColor: 'green',
        bodyPart: 'nose',
        ballPosition: { x: 500, y: 400 },
    },
    {
        instructionText: 'Nose',
        ballColor: 'green',
        bodyPart: 'nose',
        ballPosition: { x: 300, y: 300 },
    },
    {
        instructionText: 'Left hand',
        ballColor: 'green',
        bodyPart: 'leftIndex',
        ballPosition: { x: 100, y: 200 },
    },
    {
        instructionText: 'Right hip',
        ballColor: 'green',
        bodyPart: 'rightHip',
        ballPosition: { x: 100, y: 300 },
    },
];

// Helper function to get the landmark index for a body part
export const getBodyPartIndex = (bodyPart: string): number  => {
    const bodyPartIndices: { [key: string]: number } = {
        nose: 0,
        leftEyeInner: 1,
        leftEye: 2,
        leftEyeOuter: 3,
        rightEyeInner: 4,
        rightEye: 5,
        rightEyeOuter: 6,
        leftEar: 7,
        rightEar: 8,
        mouthLeft: 9,
        mouthRight: 10,
        leftShoulder: 11,
        rightShoulder: 12,
        leftElbow: 13,
        rightElbow: 14,
        leftWrist: 15,
        rightWrist: 16,
        leftPinky: 17,
        rightPinky: 18,
        leftIndex: 20,
        rightIndex: 19,
        leftThumb: 21,
        rightThumb: 22,
        leftHip: 23,
        rightHip: 24,
        leftKnee: 26,
        rightKnee: 25,
        leftAnkle: 27,
        rightAnkle: 28,
        leftHeel: 29,
        rightHeel: 30,
        leftFoot: 32,
        rightFoot: 31,
    };
    if (bodyPartIndices[bodyPart] === undefined) {
        throw new Error(`Body part ${bodyPart} not found`);
    }
  
    return bodyPartIndices[bodyPart];
}

// Function to shuffle an array
function shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

// Function to get randomized instructions
export const getInstructions = (): typeof instructions => {
    return shuffleArray([...instructions]);
}