// App.tsx

import React, { useState, useEffect } from 'react';
import PoseGame from './PoseGame';
import CircularControl from './CircularControl';
import FrequencyTimer from './FrequencyTimer';
import chimeSound from './sounds/bells.mp3';
import './App.css';

const audio = new Audio(chimeSound);

// Move the check outside the component
const isMobileOrTablet = () => {
  // Check if running in browser environment
  if (typeof window === 'undefined') return false;
  
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) || window.innerWidth <= 768;
};

function App() {
  // Use the function in useState
  const [isSmallScreen] = useState(isMobileOrTablet());

  

  const [showWelcome, setShowWelcome] = useState(true);

  // Adjust this to test game itself
  const [showGame, setShowGame] = useState(false);

  const [sessionLength, setSessionLength] = useState(2); // in minutes
  const [frequency, setFrequency] = useState(25); // in minutes
  const [musclePercentage, setMusclePercentage] = useState(0); // Placeholder

  const [frequencyTimeLeft, setFrequencyTimeLeft] = useState(frequency * 60); // Frequency timer in seconds

  const [completedMovements, setCompletedMovements] = useState(() => {
    // Initialize with today's movements from localStorage
    const today = new Date().toISOString().split('T')[0];
    return parseInt(localStorage.getItem(`movements_${today}`) || '0');
  });

  // Add this useEffect to update frequencyTimeLeft when frequency changes
  useEffect(() => {
    setFrequencyTimeLeft(frequency * 60);
  }, [frequency]);

  // const [showConsentModal, setShowConsentModal] = useState(false);
  // const [isFirstVisit, setIsFirstVisit] = useState(() => {
  //   return localStorage.getItem('hasVisitedBefore') !== 'true';
  // });


  // Start the session
  const handleStartSession = () => {
    setShowWelcome(false);
    setShowGame(true);
    setFrequencyTimeLeft(frequency * 60); // Reset frequency timer when session starts
    
  };

  const handleGameCompletion = (newMovements: number) => {
    const today = new Date().toISOString().split('T')[0];
    const updatedMovements = completedMovements + newMovements;
    
    // Update localStorage
    localStorage.setItem(`movements_${today}`, updatedMovements.toString());
    
    // Update state
    setCompletedMovements(updatedMovements);
    setShowGame(false);
    setShowWelcome(true);
  };

  const handleTimerComplete = () => {
    // audio.volume = 0;
    // audio.play().catch(() => {});
    // audio.volume = 1;
    alert('Ready for a break!');
    handleStartSession();
  };

  // Early return if on mobile/tablet
  if (isSmallScreen) {
    return (
      <div className="mobile-message">
        <h1>Sorry, desktop only.</h1>
        <p>MotionBreak requires a good sized screen and well positioned camera.</p>
        <p>It displays large text, meant to be read from a distance.</p>
        <p>It's best to use while working, to help you remember to move.</p>
      </div>
    );
  }

  return (
    <div className="app">
      
      {showWelcome && (
        <div className="welcome-screen">
          <h1 style={{ fontFamily: 'logo' }}>Motion Break!</h1>
          <CircularControl
            sessionLength={sessionLength}
            setSessionLength={setSessionLength}
            frequency={frequency}
            setFrequency={setFrequency}
            completedMovements={completedMovements}
          />
        </div>
      )}
      {showGame && (
        <PoseGame
          onGameComplete={handleGameCompletion}
          sessionLength={sessionLength}
        />
      )}
      {/* Include the frequency timer with the "Start Now" button */}

      {!showGame && ( 
        <FrequencyTimer 
          timeLeft={frequencyTimeLeft}
          onStartNow={handleStartSession}
          onTimerComplete={handleTimerComplete}
        />
      )}
    </div>
  );
}

export default App;
