// CircularControl.tsx

import React from 'react';
import './CircularControl.css';

interface CircularControlProps {
  sessionLength: number;
  setSessionLength: (value: number) => void;
  frequency: number;
  setFrequency: (value: number) => void;
  completedMovements: number;
}

const CircularControl: React.FC<CircularControlProps> = ({
  sessionLength,
  setSessionLength,
  frequency,
  setFrequency,
  completedMovements,
}) => {
  // Handlers for adjusting values
  const handleSessionIncrease = () => {
    setSessionLength(sessionLength + 1);
  };

  const handleSessionDecrease = () => {
    if (sessionLength > 1) {
      setSessionLength(sessionLength - 1);
    }
  };

  const handleFrequencyIncrease = () => {
    setFrequency(frequency + 5);
  };

  const handleFrequencyDecrease = () => {
    if (frequency > 5) {
      setFrequency(frequency - 5);
    }
  };

  return (
    <div className="circular-control">
      <div>
        <div className="adjustment-buttons">
          <div className="adjustment-section">
            <button onClick={handleSessionIncrease}>+</button>
            <p>Break Length</p>
            <h2>{sessionLength} min</h2>
            <button onClick={handleSessionDecrease}>-</button>
          </div>
          <div className="adjustment-section">
            <button onClick={handleFrequencyIncrease}>+</button>
            <p>Run every</p>
            <h2>{frequency} min</h2>
            <button onClick={handleFrequencyDecrease}>-</button>
          </div>
        </div>
        <div className="inner-circle">
          <h2>{completedMovements}</h2>
          <p>Movements Today</p>
        </div>
      </div>
    </div>
  );
};

export default CircularControl;
