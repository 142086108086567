// PoseGame.tsx

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import { Camera } from '@mediapipe/camera_utils';
import { Pose, POSE_CONNECTIONS, Results } from '@mediapipe/pose';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { getInstructions, getBodyPartIndex } from './instructions';
import './PoseGame.css'; // We'll create a separate CSS file for the component
import { throttle } from 'underscore';

interface PoseGameProps {
    onGameComplete: (completedMovements: number) => void;
    sessionLength: number;
    // timeLeft: number;
    // setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
}

const PoseGame: React.FC<PoseGameProps> = ({ onGameComplete, sessionLength }) => {

// function PoseGame() {
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ballRadius = 30;


  const currentInstructionIndex = useRef(0);
  const isTaskCompleted = useRef(false);

  // Timer state
  // const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
  const [timeLeft, setTimeLeft] = useState(sessionLength * 60); // Convert minutes to seconds

  // Add this to track which instruction's ball should be visible
  const [visibleBallIndex, setVisibleBallIndex] = useState(0);


  const [instructions, setInstructions] = useState(getInstructions());

  const cameraRef = useRef<Camera | null>(null);
  const poseRef = useRef<Pose | null>(null);
  const isCleanedUp = useRef(false);  // Add flag to track cleanup state

  // Add this ref to track completed movements
  const completedMovementsRef = useRef(0);

  // Add consolidated cleanup function
  const cleanup = useCallback(() => {
    if (isCleanedUp.current) return;  // Prevent multiple cleanups
    
    if (cameraRef.current) {
      cameraRef.current.stop();
      cameraRef.current = null;
    }
    if (poseRef.current) {
      poseRef.current.close();
      poseRef.current = null;
    }
    
    isCleanedUp.current = true;
  }, []);

  // Helper function to format time in mm:ss
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const paddedSeconds = seconds.toString().padStart(2, '0');
    return `${minutes}:${paddedSeconds}`;
  };

  useEffect(() => {
    isCleanedUp.current = false;  // Reset cleanup flag on mount/re-mount
    
    const pose = new Pose({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
      },
    });
    poseRef.current = pose;
    pose.setOptions({
      selfieMode: true,
      modelComplexity: 0,
      smoothLandmarks: true,
      enableSegmentation: false,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    
    
    // Usage remains the same
    // const throttledOnResults = throttle((results: any) => {
    //   onResults(results);
    // }, 100);

    pose.onResults(onResults);


    if (webcamRef.current !== null && webcamRef.current.video !== null) {
      const camera = new Camera(webcamRef.current.video, {
        onFrame: async () => {
          // Only process frames if not cleaned up
          if (!isCleanedUp.current && poseRef.current) {
            await poseRef.current.send({ image: webcamRef.current!.video! });
          }
        },
        width: 640,
        height: 480,
      });
      cameraRef.current = camera;
      camera.start();
    }

    return cleanup;
  }, [currentInstructionIndex, cleanup]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      alert('Session complete!');
      cleanup();
      if (onGameComplete) {
        onGameComplete(completedMovementsRef.current);
      }
    }
  }, [timeLeft, onGameComplete, cleanup]);

  const onResults = (results: Results) => {
    if (!webcamRef.current?.video || !canvasRef.current) return;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');
    if (canvasCtx == null) throw new Error('Could not get context');

    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasElement.width = videoWidth;
    canvasElement.height = videoHeight;

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Fill the canvas with black
    canvasCtx.fillStyle = 'black';
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Draw pose landmarks
    if (results.poseLandmarks) {
      // Draw pose connections
      drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS, {
        color: '#ff0000',
        lineWidth: 4,
      });
      
      // Draw pose landmarks
      drawLandmarks(canvasCtx, results.poseLandmarks, {
        color: '#ff0000',
        lineWidth: 2,
      });

      const currentInstruction = instructions[currentInstructionIndex.current];
      const bodyPartIndex = getBodyPartIndex(currentInstruction.bodyPart);

      if (bodyPartIndex !== null) {
        const bodyPartLandmark = results.poseLandmarks[bodyPartIndex];

        if (bodyPartLandmark) {
          const x = bodyPartLandmark.x * canvasElement.width;
          const y = bodyPartLandmark.y * canvasElement.height;

          // Draw the body part landmark for debugging (optional)
          canvasCtx.beginPath();
          canvasCtx.arc(x, y, 5, 0, 2 * Math.PI);
          canvasCtx.fillStyle = 'blue';
          canvasCtx.fill();

          const distance = Math.hypot(
            x - currentInstruction.ballPosition.x,
            y - currentInstruction.ballPosition.y
          );

          if (distance < ballRadius + 20) {
            if (!isTaskCompleted.current) {
              // Increment the completed movements counter
              completedMovementsRef.current += 1;

              isTaskCompleted.current = true;

              if (currentInstructionIndex.current < instructions.length - 1) {
                currentInstructionIndex.current += 1;
                isTaskCompleted.current = false;
              } else {
                onGameComplete(completedMovementsRef.current);
              }
            }
          }
        }
      }

      // Draw the current ball if task isn't completed
      if (currentInstruction && !isTaskCompleted.current) {
        canvasCtx.beginPath();
        canvasCtx.arc(
          currentInstruction.ballPosition.x,
          currentInstruction.ballPosition.y,
          ballRadius,
          0,
          2 * Math.PI
        );
        canvasCtx.fillStyle = currentInstruction.ballColor;
        canvasCtx.fill();
      }
    }

    canvasCtx.restore();
  };

  return (
    <div className="pose-game-container">
      <div className="video-container">
        <Webcam ref={webcamRef} className="webcam" style={{ display: 'none' }} />
        <canvas ref={canvasRef} className="canvas" />
      </div>
      <div className="instruction-container">
        {/* Instructions */}
        <div className="instructions">
          {instructions[currentInstructionIndex.current] ? (
            <div className="instruction-content">
              <div
                className="instruction-ball"
                style={{ backgroundColor: instructions[currentInstructionIndex.current].ballColor }}
              ></div>
              <h2>{instructions[currentInstructionIndex.current].instructionText}</h2>
            </div>
          ) : (
            <h2>All tasks completed</h2>
          )}
        </div>

        {/* Timer display */}
        <div className="timer-container">
          <p className="timer">{formatTime(timeLeft)}</p>
        </div>
      </div>
    </div>
  );
}

export default PoseGame;
